<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
      { 'show-background': $route.path == '/dashboard/home' || showBackgroundWhen() },
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper" :class="contentWidth === 'boxed' ? 'container p-0' : null">
      <!-- <slot name="breadcrumb">
        <app-breadcrumb />
      </slot> -->
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    // AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
  mounted() {},
  methods: {
    showBackgroundWhen() {
      let pagesAllowed = [
        {
          name: "SlothMove",
        },
        {
          name: "SlothGuard",
        },
        {
          name: "SlothUtility",
        },
        {
          name: "SlothProtect",
        },
        {
          name: "ParkingTicketFighter",
        },
        {
          name: "SlothQueue",
        },
        {
          name: "LifeAdmin",
        },
      ];

      return pagesAllowed.find((page) => page.name == this.$route.name);
    },
  },
};
</script>

<style>
.show-background {
  /*background-image: url("/main-background.png");*/
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  transition: all 0.7s ease;
}

.app-content {
  background-color: transparent;
}
</style>
