export default [
  {
    header: 'Services',
  },

  {
    title: 'Broadband',
    icon: 'ServerIcon',
    route: 'dashboard-broadband',
  },
 /*  {
    title: 'Legal Care',
    icon: 'BookOpenIcon',
    route: 'legal-care',
  }, */
  {
    title: 'Rewards',
    icon: 'HeartIcon',
    route: 'apps-rewards',
  },
  {
    title: 'Security',
    icon: 'PocketIcon',
    route: 'dashboard-security',
  },
  {
    title: 'FAQs',
    icon: 'HelpCircleIcon',
    route: 'pages-faq',
  },
]
