<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    v-if="userData"
  >
    <template #button-content>
      <div class="d-flex user-nav">
        <p class="user-name font-weight-bolder mb-0 ml-0">
          {{ userInfo() }}
        </p>

        <!-- <span class="user-status" v-if="userPlan.plan === 0"> Free </span>
        <span class="user-status" v-if="userPlan.plan === 1"> SlothMover </span>
        <span class="user-status" v-if="userPlan.plan === 2"> Jungle Sovereign </span> -->
      </div>
      <b-avatar
        size="40"
        :src="require(`@/assets/images/profile/slot-profile.png`)"
        variant="light-primary"
        badge
        class="ml-2 badge-minimal"
        badge-variant="success"
        ><!-- :src="userData.avatar" -->
        <feather-icon v-if="!userData.firstName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item :to="'/apps/users/edit'" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>

    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->

    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
    <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item> -->

    <!-- Hide for simplified version -->
    <!-- <b-dropdown-item :to="{ name: 'pages-pricing' }" link-class="d-flex align-items-center" :disabled="!currentMove['allDataProvided']">
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
    <b-dropdown-item :to="{ name: 'pages-faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { mapState } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: null,
      avatarText,
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    userInfo() {
      return this.userData["firstName"] + " " + this.userData["lastName"];
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage and Vuex Store
      localStorage.removeItem("userData");

      localStorage.clear();
      this.clearCookies();

      this.$store.commit("app/setField", ["user", {}]);

      //Reset user plam
      this.$store.commit("app/setField", ["userPlan", { plan: undefined, endDate: "" }]);

      //Reset Move Objects
      this.$store.commit("app/setField", ["currentMove", {}]);
      this.$store.commit("app/setField", ["lastMove", {}]);

      // Reset ability
      this.$ability.update(initialAbility);

      //Reboot Miniform's flow
      this.$store.commit("app/setField", ["showWizardForm", true]);
      this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
      this.$store.commit("app/setField", ["displayLabelAboutData", false]);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    clearCookies() {
      let cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
  },
  computed: {
    ...mapState("app", ["lastMove", "userPlan", "currentMove"]),
  },
};
</script>
