<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <!-- Address changer -->
    <li
      class="nav-item"
    >
      <b-link
        :href="`https://form.slothmove.com/?smfEmail=${user.email}`"
        target="_blank"
        class="d-flex align-items-center"
        :disabled="!lastMove['verifiedDetails'] ||!currentMove['allDataProvided']"
        :class="{'nav-items-disabled': !lastMove['verifiedDetails'] || !currentMove['allDataProvided']}"
      >
        <feather-icon :icon="'EditIcon'" />
        <span class="menu-title text-truncate">Address changer</span>
      </b-link>
    </li>
    <!-- Address changer -->
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { BLink } from 'bootstrap-vue'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import { mapState } from 'vuex';
export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BLink
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  computed:{
    ...mapState("app", ["user", "lastMove","currentMove"]),
  }
}
</script>
<style>
.nav-items-disabled{
  color:#625f6e85!important;
}
</style>
