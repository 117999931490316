<template>
  <div class="quick-actions-menu mt-auto">
    <div class="buttons-container">
      <h4 class="font-weight-bolder text-white mb-1">Quick Actions</h4>

      <!-- v-if="!isPassedBy1Month(currentMove.moveDetails['moveInDate'])" -->
      <div
        class="action action-displayed align-items-center mb-1"
        @click="openDialogChangeMoveDate()"
        style="display: none"
      >
        <img :src="`${urlBucket}/dashboard/mini-calendar.png`" class="mr-1" width="22px" alt="" />
        <p class="mb-0 font-weight-bolder" style="font-size: 11px; color: black">
          Change my moving date
        </p>
      </div>

      <div
        class="action action-displayed align-items-center mb-1"
        @click="openDialogChangeMoveAddress()"
        style="display: none;background-color: #edffc5"
      >
        <img :src="`${urlBucket}/form/assets/icons/pin.svg`" class="mr-1" width="22px" alt="" />
        <p class="mb-0 font-weight-bolder" style="font-size: 11px; color: black">
          Change New Address
        </p>
      </div>

      <div
        class="action action-displayed align-items-center mb-1"
        @click="openDialogAddMoreServices()"
        style="display: none;background-color: #c5ffe0; max-width: 450px"
      >
        <img
          :src="`${urlBucket}/dashboard/airplane-sheet.png`"
          class="mr-1"
          width="22px"
          height="22px"
          alt=""
        />
        <p class="mb-0 font-weight-bolder" style="font-size: 11px; color: black">
          Add more services
        </p>
      </div>

      <div
        class="action pause-my-move align-items-center mb-1"
        @click="openDialogPauseMyMove()"
        style="background-color: #c5e8ff; display: none"
      >
        <img
          :src="`${urlBucket}/dashboard/error-icon.png`"
          class="mr-1"
          width="22px"
          style="opacity: 0.7"
          alt=""
        />
        <p class="mb-0 font-weight-bolder" style="font-size: 11px; color: black">Pause My Move</p>
      </div>
    </div>

    <!-- Modal - Change move date -->
    <vue-modal v-model="showModalChangeDate" :enable-close="enableClose">
      <!--  v-if="!lastMove.paused"-->
      <div v-if="!currentMove.paused">
        <h3 class="font-weight-bolder mb-2" autofocus>Change move date</h3>
        <p class="mb-0">Not sure on your new move date just yet?</p>
        <p class="cursor-pointer" style="text-decoration: underline" @click="pauseYourMove()">
          Click here and we'll pause your move
        </p>
        <!-- Dates -->
        <div class="row my-2">
          <div class="col">
            <div class="row mb-2">
              <div class="col-12 col-md-5 d-flex align-items-center">
                <p class="font-weight-bolder mb-0" style="font-size: 16px">Current Move in date</p>
              </div>

              <div class="col-12 col-md-6">
                <input
                  class="form-control text-black"
                  type="date"
                  :value="currentMoveInDate"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-5 d-flex align-items-center">
                <p class="font-weight-bolder mb-0" style="font-size: 16px">Current Move out date</p>
              </div>

              <div class="col-12 col-md-6">
                <input
                  class="form-control text-black"
                  type="date"
                  :value="currentMoveOutDate"
                  disabled
                />
              </div>
            </div>
            <hr
              class="px-3"
              style="
                margin-top: 1rem;
                margin-bottom: 2rem;
                border-top: 3px solid #d2d2d2;
                width: 70%;
              "
            />
            <div class="row mb-2">
              <div class="col-12 col-md-5 d-flex align-items-center">
                <p class="font-weight-bolder mb-0" style="font-size: 16px">New Move in date</p>
              </div>

              <div class="col-12 col-md-7">
                <DateMultiSelect
                  :dateModel="moveDetails['moveInDate']"
                  @formattedDate="(value) => (moveDetails['moveInDate'] = value)"
                  :isDOB="false"
                  :showMessage="false"
                />
                <span class="text-danger error-move-in-date">*This field is required</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-5 d-flex align-items-center">
                <p class="font-weight-bolder mb-0" style="font-size: 16px">New Move out date</p>
              </div>

              <div class="col-12 col-md-7">
                <DateMultiSelect
                  :dateModel="moveDetails['moveOutDate']"
                  @formattedDate="(value) => (moveDetails['moveOutDate'] = value)"
                  :isDOB="false"
                  :showMessage="false"
                />
                <span class="text-danger error-move-out-date">*This field is required</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Terms -->
        <div class="d-flex mb-2">
          <div class="checkbox-wrapper-30">
            <span class="checkbox">
              <input type="checkbox" v-model="acknowledgedTerms" />
              <svg>
                <use xlink:href="#checkbox-30" class="checkbox"></use>
              </svg>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
              <symbol id="checkbox-30" viewBox="0 0 22 22">
                <path
                  fill="none"
                  stroke="currentColor"
                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                />
              </symbol>
            </svg>
          </div>
          <p class="mb-0 ml-1" style="font-size: 10px; line-height: 1.1">
            I acknowledge that changing my move details after my updates have been partially or
            fully dispatched may affect the accuracy of my address update
          </p>
        </div>
        <span class="text-danger error-terms">*This field is required</span>
      </div>

      <div v-else>
        <div class="d-flex justify-content-center align-items-center" style="height: 400px">
          <h2 class="font-weight-bolder mb-2">Your move is currently on pause</h2>
        </div>
      </div>

      <!-- Buttons -->
      <div class="d-flex justify-content-center">
        <button
          class="button button-close mr-3"
          @click="(enableClose = true), (showModalChangeDate = false)"
        >
          Cancel
        </button>
        <!-- v-if="!lastMove.paused" -->
        <button class="button button-update" v-if="!currentMove.paused" @click="updateMove()">
          Update
        </button>
        <button class="button button-update" v-else @click="resumeLastMove()">Resume</button>
      </div>
    </vue-modal>

    <!-- Modal - Update/Change my new address -->
    <vue-modal v-model="showModalChangeAddress" :enable-close="enableClose">
      <!--  v-if="!lastMove.paused"-->
      <div>
        <h3 class="font-weight-bolder mb-2" autofocus>
          Update my new address
          <img :src="`${urlBucket}/form/assets/icons/pin.svg`" class="mr-1" width="22px" alt="" />
        </h3>
        <p class="mb-1">Use this portal to change the address to wich you moving are moving</p>

        <p class="font-weight-bolder" style="margin-bottom: 5px">Current New Address</p>
        <p class="mb-2">{{ currentMove.moveDetails["moveToAddress"] }}</p>

        <p class="font-weight-bolder" style="margin-bottom: 7px">Updated This Address to</p>
        <!-- vue select / finder -->
        <div class="mb-4">
          <div class="d-flex align-items-center search">
            <vSelect
              :filterable="false"
              :value="moveToAddress"
              class="w-100 fs-md"
              :options="options"
              :clearable="false"
              :placeholder="`Your new postcode`"
              :multiple="false"
              @search="
                (search, loading) => onSearch(search.toUpperCase(), loading, 'moveToPostCode')
              "
              @input="
                (e) => {
                  moveToAddress = e.label;
                  moveToPostCode = e.postcode.trim();
                  completeMoveToAddress = e;
                }
              "
              ><!--:dropdown-should-open="dropdownShouldOpen" -->
              <template slot="selected-option" slot-scope="option">
                <!-- {{ option.label.length >= 47 ? option.label.slice(0, 47) + "..." : option.label }} -->
                {{ formatOptionSelected(option) }}
              </template>
            </vSelect>
          </div>
          <span class="text-danger error-move-in-address">*This field is required</span>
        </div>

        <!-- Terms -->
        <div class="d-flex mb-2">
          <div class="checkbox-wrapper-30">
            <span class="checkbox">
              <input type="checkbox" v-model="acknowledgedTerms" />
              <svg>
                <use xlink:href="#checkbox-30" class="checkbox"></use>
              </svg>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
              <symbol id="checkbox-30" viewBox="0 0 22 22">
                <path
                  fill="none"
                  stroke="currentColor"
                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                />
              </symbol>
            </svg>
          </div>
          <p class="mb-0 ml-1" style="font-size: 10px; line-height: 1.1">
            I understand that changing my address after my updates have been partially or fully
            dispatchedmay affect the accuracy of my address update.
          </p>
        </div>
        <span class="text-danger error-terms">*This field is required</span>
      </div>

      <!-- Buttons -->
      <div class="d-flex justify-content-center">
        <button
          class="button button-close mr-3"
          @click="(enableClose = true), (showModalChangeAddress = false)"
        >
          Cancel
        </button>
        <!-- v-if="!lastMove.paused" -->
        <button class="button button-update" @click="updateAddress()">Update</button>
      </div>
    </vue-modal>

    <!-- Modal - Add/More services -->
    <vue-modal v-model="showModalAddMoreServices" :enable-close="enableClose">
      <div>
        <div class="row d-flex justify-content-between mt-1 mb-2">
          <h3 class="col-9 col-sm-11 font-weight-bolder" autofocus>
            Which Address updates would you like to add?
          </h3>
          <x-icon
            size="1.7x"
            class="cursor-pointer col-3 col-sm-1"
            @click="(enableClose = true), (showModalAddMoreServices = false)"
          ></x-icon>
        </div>
        <div class="input-search-container mb-1">
          <vSelect
            class="w-100 fs-md"
            label="name"
            :clearable="false"
            :options="services"
            :placeholder="`e.g.'TV Licence'`"
            :multiple="false"
            @option:selected="(ev) => selectService(ev)"
          >
            <!--  -->
            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <!-- <img :src="`${urlBucket}/${option.icon}`" width="30px" class="mr-1" /> -->
                <p class="mb-0 fs-md">{{ option.name }}</p>
              </div>
            </template>
          </vSelect>
        </div>
        <p class="text-danger error-service">* This field is required</p>

        <div class="chip-list-wrapper mb-1">
          <div
            class="chip mb-2 cursor-pointer"
            v-for="(item, index) in servicesSelected"
            :key="index"
            @click="deleteService(index)"
          >
            <i class="fa-sharp fa-solid fa-circle-check"></i>
            <p class="mb-0" style="margin-left: 5px">{{ item.name }}</p>
          </div>
        </div>

        <!-- <div
          v-if="servicesSelected.length > 0"
          class="label-message d-flex align-items-center mt-1 mb-1"
        >
          <img
            :src="`${urlBucket}/dashboard/check-y.png`"
            width="30px"
            style="margin-left: 10px; margin-right: 10px"
          />
          <p class="ms-1 mb-0 text-white" style="font-size: 15px; color: white !important">
            Excellent, please check your Actions afterwards
          </p>
        </div> -->
        <p
          v-if="sended || servicesSelected.length > 0"
          class="p-1"
          style="
            background-color: rgb(197, 255, 224);
            border-radius: 5px;
            border: 1px solid rgb(176, 228, 200);
          "
        >
          You may have to enter extra information in order for your request to be attended
        </p>
        <!-- Buttons -->
        <div class="d-flex justify-content-center mb-2">
          <button
            class="button button-complete d-flex align-items-center justify-content-center"
            @click="addMoreServices()"
          >
            <span>Complete</span>
            <div v-if="sending" class="spinner-border text-light ml-1" role="status"></div>
          </button>
        </div>
      </div>

      <!-- Buttons -->
    </vue-modal>

    <!-- Modal - Update/Pause My Move - Cancel -->
    <vue-modal v-model="showModalPauseMyMove" :enable-close="enableClose">
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="font-weight-bolder mb-2" autofocus>No longer moving</h3>
          <x-icon
            size="1.7x"
            class="cursor-pointer"
            @click="(enableClose = true), (showModalPauseMyMove = false)"
          ></x-icon>
        </div>

        <p class="font-weight-bolder" style="margin-bottom: 5px; text-decoration: underline">
          Still planning to move but unsure about the when and where?
        </p>
        <p class="mb-2">
          No problem! Click the ‘Pause My Move’ button below. Whenever you're ready with your new
          moving date and location, just log back in and let us know. We're here to help!
        </p>

        <p class="font-weight-bolder" style="margin-bottom: 7px; text-decoration: underline">
          Not moving home at all?
        </p>
        <p class="mb-2">
          No worries, just hit the 'Cancel' button below, and we'll handle the cancellation of your
          move.
        </p>
        <!-- Terms -->
        <div class="d-flex align-items-center mt-1 mb-2">
          <div class="checkbox-wrapper-30">
            <span class="checkbox">
              <input type="checkbox" v-model="acknowledgedTerms" />
              <svg>
                <use xlink:href="#checkbox-30" class="checkbox"></use>
              </svg>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
              <symbol id="checkbox-30" viewBox="0 0 22 22">
                <path
                  fill="none"
                  stroke="currentColor"
                  d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
                />
              </symbol>
            </svg>
          </div>
          <p class="mb-0 ml-1 text-justify" style="font-size: 11px; line-height: 1.3">
            I understand that if I cancel or pause my move after my updates have already been
            dispatched, some of my address updates might still go through. We'll send out
            cancellation requests for any updates we've already processed. Please be aware, however,
            that if
            <span class="font-weight-bolder">
              your DVLA address updates have been completed, we can't reverse them.
            </span>
            In such cases, you'll need to collect new V5C documents or driving licenses from the
            address you initially provided us.
          </p>
        </div>
        <span class="text-danger error-terms">*This field is required</span>
        <!-- Buttons -->
        <div class="d-flex justify-content-center">
          <button class="button button-pause mr-3" @click="confirmPauseMove()">Pause</button>
          <button class="button button-danger-cancel" @click="cancelMove()">Cancel</button>
        </div>
      </div>

      <!-- Buttons -->
    </vue-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axiosCustom from "@axios";

import DateMultiSelect from "./../../../../../views/dashboard/ecommerce/DateMultiSelect.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import { XIcon } from "vue-feather-icons";
import { isValid } from "postcode";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import {
  loyalty,
  energySuppliers,
  charities,
  gps,
  pensions,
  gyms,
  opticians,
  mobiles,
  dentists,
  //banks,
  countries,
} from "../../../../../views/dashboard/lists.js";
export default {
  name: "QuickActionsMenu",
  components: {
    flatPickr,
    DateMultiSelect,
    VueModal,
    vSelect,
    XIcon,
  },
  data() {
    return {
      // dropBlocked: true,
      error: {
        moveInDate: false,
        moveOutDate: false,
        moveInAddress: false,
        terms: false,
        inputServices: false,
      },
      sending: false,
      enableClose: false,
      acknowledgedTerms: true,
      //Quick Action - Change move date
      showModalChangeDate: false,
      currentMoveInDate: "",
      currentMoveOutDate: "",
      moveDetails: {
        moveInDate: "",
        moveOutDate: "",
      },
      configInputDate: {
        dateFormat: "d-m-Y",
      },
      //Quick Action - Change New Address
      showModalChangeAddress: false,
      moveToAddress: "",
      moveToPostCode: "",
      completeMoveToAddress: "",
      options: [],
      //Quick Action - Add more services
      showModalAddMoreServices: false,
      services: [],
      servicesSelected: [],
      sended: false,
      //Quick Action - Pause My Move
      showModalPauseMyMove: false,
    };
  },
  mounted() {
    // console.log("QuickActionsMenu", this.currentMove);

    if (this.currentMove["paused"]) {
      this.showModalChangeDate = true;
    }

    const fillServices = (array) => {
      let unique = [...new Set(array)];
      let result = unique.filter((item) => item.value.trim() !== "");
      return result;
    };

    this.services = fillServices([
      ...[
        //...loyalty,
        ...energySuppliers,
        //...charities,
        ...gps,
        // ...pensions,
        //...gyms,
        //...mobiles,
        //...opticians,
        //...dentists,
        //...banks,
      ].map((item) => {
        return { name: item, value: item };
      }),
      { name: "DWP", value: "informDWP" },
      { name: "HMRC", value: "informHMRC" },
      /* { name: "Student Finance", value: "informStudentFinance" },
      { name: "Premium Bonds", value: "informBonds" },
      { name: "Postcode Lottery", value: "informLottery" }, */
      { name: "NHS", value: "informNHS" },
    //  { name: "Dentist", value: "informDentist" },
      { name: "TV Licence", value: "informTVLicense" },
      { name: "Electoral Roll", value: "informElectoralRoll" },
      { name: "DVLA Licence", value: "license" },
      { name: "DVLA v5c", value: "vehicle" },
    ]).sort((a, b) => a.name.localeCompare(b.name));
  },
  methods: {
    // Vue Dropdown behaviour
    // dropdownBehaviour(search) {
    //   if (search.length > 0) {
    //     this.dropBlocked = false;
    //   } else {
    //     this.dropBlocked = true;
    //   }
    // },
    //
    openDialogChangeMoveDate() {
      console.log("openDialogChangeMoveDate", this.lastMove);
      this.currentMoveInDate = this.currentMove?.moveDetails?.moveInDate;
      this.currentMoveOutDate = this.currentMove?.moveDetails?.moveOutDate;
      this.showModalChangeDate = true;
      this.enableClose = false;
    },
    validateInputDate() {
      document.querySelector(".error-move-in-date").style.display = "none";
      document.querySelector(".error-move-out-date").style.display = "none";

      if (this.moveDetails.moveInDate == "") {
        this.error.moveInDate = true;
        document.querySelector(".error-move-in-date").style.display = "block";
      } else {
        this.error.moveInDate = false;
      }

      if (this.moveDetails.moveOutDate == "") {
        this.error.moveOutDate = true;
        document.querySelector(".error-move-out-date").style.display = "block";
      } else {
        this.error.moveOutDate = false;
      }

      this.handleTerms();
    },
    pauseYourMove() {
      if (this.sending) return;
      this.sending = true;

      axiosCustom
        .post("api/user/pauseLastMove", {
          move: this.lastMove,
        })
        .then(async (res) => {
          console.log("pauseLastMove", res);
          await this.getDetails();
          this.sending = false;
          this.showModalChangeDate = false;

          setTimeout(() => {
            this.showModalChangeDate = true;
          }, 200);
        })
        .finally(() => {});
    },
    updateMove() {
      this.validateInputDate();
      if (this.sending || this.error.moveInDate || this.error.moveOutDate || this.error.terms)
        return;
      this.sending = true;

      let move = Object.assign({}, this.currentMove);

      move.moveDetails.moveInDate = this.moveDetails.moveInDate;
      move.moveDetails.moveOutDate = this.moveDetails.moveOutDate;

      axiosCustom
        .post("api/user/updateMove", {
          move,
        })
        .then((res) => {
          console.log("updateMove", res);
          this.getDetails();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.showModalChangeDate = false;
          this.sending = false;
        });
    },
    resumeLastMove() {
      if (this.sending) return;
      this.sending = true;
      axiosCustom
        .post("api/user/resumeLastMove", {
          move: this.currentMove,
        })
        .then(async (response) => {
          console.log("resumeLastMove", response);
          await this.getDetails();
          this.sending = false;
        })
        .finally(() => {
          this.showModalChangeDate = false;
          this.sending = false;
        });
    },
    //
    //Quick Action - Change New Address
    openDialogChangeMoveAddress() {
      console.log("openDialogChangeMoveAddress", this.lastMove);
      this.showModalChangeAddress = true;
      this.enableClose = false;
    },
    validateAddressInput() {
      document.querySelector(".error-move-in-address").style.display = "none";

      if (this.moveToAddress == "") {
        document.querySelector(".error-move-in-address").style.display = "block";

        this.error.moveInAddress = true;
      } else {
        this.error.moveInAddress = false;
      }

      this.handleTerms();
    },
    updateAddress() {
      this.validateAddressInput();
      if (this.sending || this.error.moveInAddress || this.error.terms) return;
      this.sending = true;

      let move = Object.assign({}, this.currentMove);

      move.moveDetails.completeMoveToAddress = this.completeMoveToAddress;
      move.moveDetails.moveToAddress = this.moveToAddress;
      move.moveDetails.moveToPostCode = this.moveToPostCode;

      console.log(move.moveDetails);

      axiosCustom
        .post("api/user/updateMove", {
          move: this.currentMove,
        })
        .then((res) => {
          console.log("updateMove", res);
          this.getDetails();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.showModalChangeAddress = false;
          this.sending = false;
        });
    },
    onSearch(search, loading, postcodeType) {
      const formatPostcode = (postcode) => {
        postcode = postcode.split("");
        if (postcode.length == 5) {
          postcode.splice(2, 0, ".");
        } else if (postcode.length == 6) {
          postcode.splice(3, 0, ".");
        } else if (postcode.length == 7) {
          postcode.splice(4, 0, ".");
        }

        return postcode.join("").replace(".", " ");
      };

      if (search.trim().search(" ") < 0) {
        search = formatPostcode(search.trim());
      }

      // console.log(search);

      if (search.length > 4 && isValid(search.toUpperCase().trim())) {
        loading(true);

        axiosCustom
          .get(`/addresses/postcodeLookup?stream=${search}`)
          .then(({ data }) => {
            console.log(data);

            const postcode = search.trim();

            let array = data.addresses.map((item) => {
              item.postcode = postcode;
              item.label = item.formatted_address.join(" ");

              //latitude and longitude
              item.latitude = data["latitude"];
              item.longitude = data["longitude"];

              return item;
            });

            this.options = array;

            loading(false);
          })
          .catch((ev) => {
            console.error(ev);
            loading(false);
          });
      }
    },
    setCompleteAddress(addressName, postcodeType, data) {
      // console.log(data)
      this.$store.commit("formStore/setField", [addressName, data]);
      this.$store.commit("formStore/setField", [postcodeType, data.postcode.trim()]);

      // fetch(`${this.baseURL}/addresses/getFullComplete?stream=${id}`, {
      //   method: "GET",
      //   mode: "cors",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // }).then((res) => {
      //   res.json().then((json) => {
      //     this.$store.commit("formStore/setField", [addressName, json]);
      //     this.$store.commit("formStore/setField", [postcodeType, json.postcode]);
      //   });
      // });
    },
    formatOptionSelected(option) {
      return option.label.length >= 47 && window.innerWidth <= 475
        ? option.label.slice(0, 47) + "..."
        : option.label;
    },
    /* dropdownShouldOpen(VueSelect) {
      return VueSelect.search.length > 5 && VueSelect.open;
    }, */
    //
    //Quick Action - Add more services
    openDialogAddMoreServices() {
      console.log("openDialogAddMoreServices", this.currentMove);
      this.showModalAddMoreServices = true;
      this.enableClose = false;
    },
    selectService(item) {
      this.servicesSelected.push(item);
    },
    deleteService(index) {
      let array = Array.from(this.servicesSelected);
      array.splice(index, 1);
      this.servicesSelected = [...new Set(array)];
    },
    validateServicesInput() {
      document.querySelector(".error-service").style.display = "none";

      if (this.servicesSelected.length == 0) {
        this.error.inputServices = true;
        document.querySelector(".error-service").style.display = "block";
      } else {
        this.error.moveOutDate = false;
      }
    },
    addMoreServices() {
      this.sended = false;
      this.validateServicesInput();
      if (this.sending || this.error.inputServices) return;
      this.sending = true;

      axiosCustom
        .post("api/user/addMoreServices", {
          email: this.user.email,
          companies: this.servicesSelected.map((item) => item.value),
        })
        .then(async (res) => {
          console.log("addMoreServices", res);
          await this.getDetails();
          this.sended = true;
          this.showModalAddMoreServices = false;
          this.$store.commit("app/setField", ["showWizardForm", false]);

          //The user will have to go through the mini-form again in case additional information is required to add more services.
          if (this.displayWizardForm()) {
            this.$store.commit("app/setField", ["showWizardForm", true]);
          } else if (this.displayDVLAMiniform()) {
            this.$store.commit("app/setField", ["showDVLAMiniForm", true]);
          } else {
            this.reRenderTableServices();
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.servicesSelected = [];
          this.sending = false;
        });
    },
    //Quick Action - Pause My Move
    openDialogPauseMyMove() {
      console.log("openDialogPauseMyMove", this.currentMove);

      this.enableClose = false;
      if (this.currentMove["paused"]) {
        this.showModalChangeDate = true;
        return;
      }
      this.showModalPauseMyMove = true;
    },
    confirmPauseMove() {
      this.handleTerms();
      if (this.sending || this.error.terms) return;
      this.sending = true;

      axiosCustom
        .post("api/user/pauseLastMove", {
          move: this.currentMove,
        })
        .then(async (res) => {
          console.log("confirmPauseMove", res);
          await this.getDetails();
          this.sending = false;
          this.showModalPauseMyMove = false;

          setTimeout(() => {
            this.showModalChangeDate = true;
          }, 200);
        })
        .finally(() => {});
    },
    cancelMove() {
      this.handleTerms();
      if (this.sending || this.error.terms) return;
      this.sending = true;

      let move = Object.assign({}, this.currentMove);

      move.removed = true;
      axiosCustom
        .post("api/user/updateMove", {
          move,
        })
        .then(async (res) => {
          console.log("cancelMove", res);
          await this.getDetails();
        })
        .finally(() => {
          this.sending = false;
          this.showModalPauseMyMove = false;
          this.$swal.fire({
            // title: "Your move has been cancelled!",
            icon: "warning",
            text: "Your request will be reviewed and your move details will be removed as soon as possible.",
            customClass: {
              container: "alert-cancel-move",
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
    //
    handleTerms() {
      document.querySelector(".error-terms").style.display = "none";
      if (this.acknowledgedTerms) {
        this.error.terms = false;
      } else {
        document.querySelector(".error-terms").style.display = "block";
        this.error.terms = true;
      }
    },
    async getDetails() {
      try {
        let response = await axiosCustom.get(`api/user/getDetails?email=${this.user.email}`);
        let token = localStorage.getItem("accessToken");

        // response.data.accessToken = token;
        // response.data.token = token;

        let user = JSON.parse(localStorage.getItem("userData"));
        const moves = response.data.moves;
        user.moves = moves;
        localStorage.setItem("userData", JSON.stringify(user));

        // console.log(moves);
        const move = moves[moves.length - 1];

        this.$store.commit("app/setField", ["user", user]);
        this.$store.commit("app/setField", ["currentMove", move]);
        this.$store.commit("app/setField", ["lastMove", move]);
      } catch (error) {
        console.error(error);
      }
    },
    displayWizardForm() {
      let info = { ...this.currentMove["councils"], ...this.currentMove["services"] };
      if (
        !this.currentMove.moveDetails["newResidentialStatus"] ||
        this.currentMove.moveDetails["newResidentialStatus"] == ""
      ) {
        return true;
      }

      if (
        !this.currentMove["moveDetails"].moveInDate ||
        this.currentMove["moveDetails"].moveInDate == ""
      ) {
        return true;
      }

      if (
        !this.currentMove["moveDetails"].moveOutDate ||
        this.currentMove["moveDetails"].moveOutDate == ""
      ) {
        return true;
      }

      if (this.currentMove.userDetails["contactNumber"] == "") {
        return true;
      }

      if (info["informElectoralRoll"]) {
        if (info["selectedNationalities"]?.length == 0
            || info["selectedNationalities"][0]?.length == 0) {
          return true;
        }

        if (info["selectedOpenRegister"] == "") {
          return true;
        }

        if (!info["selectedPostalVote"]) {
          return true;
        }
      }

      if (info["informTVLicense"]) {
        if (!info["tvLicenseAction"]) {
          return true;
        }

        if (info["tvLicenceNumber"] == "" || !info["tvLicenceNumber"]) {
          return true;
        }
      }

      if ((info["informDentist"] && info.dentistAddress == "") || info.dentistAddress == null) {
        return true;
      }

      if (
        (info.informDVLA ||
          info.informDentist ||
          info.informLottery ||
          info.informStudentFinance ||
          info.informPension ||
          info.informHMRC ||
          info.informNHS ||
          info.informBonds ||
          info.informDWP ||
          info.informElectoralRoll ||
          info.informBanks ||
          info.informInsurance ||
          info.informPets ||
          info.informHomecare ||
          info.informBreakdown ||
          info.informMobile ||
          info.informOpticians ||
          info.selectedGyms.length > 0 ||
          (info.informLoyalty && info.selectedLoyalties.some((item) => item == "Boots"))) &&
        !this.currentMove.userDetails["dateOfBirth"]
      ) {
        return true;
      }

      if ((info.informNHS && info.gpAddress == "") || info.gpAddress == null) {
        return true;
      }

      if (
        (info.informDVLA ||
          info.informHMRC ||
          info.informDWP ||
          info.informElectoralRoll ||
          info.informStudentFinance ||
          info.informPension) &&
        !info.selectedNINO
      ) {
        return true;
      }

      if (info.informBanks && info.selectedBanksDetails !==null &&
          info.selectedBanksDetails !==undefined) {

        for (let k=0;k<info.selectedBanksDetails.length;k++){
          if (info.selectedBanksDetails[k].accountNumber===""){
            return true;
          }
        }
      }
    },
    displayDVLAMiniform() {
      let info = { ...this.currentMove["councils"], ...this.currentMove["services"] };

      return (
        info["informDVLA"] &&
        Object.entries(this.currentMove["dvlaQuestions"]).some(([atribute, value]) => {
          return value === null;
        })
      );
    },
    reRenderTableServices() {
      this.$store.commit("app/setField", ["renderTableServices", false]);
      this.$router.push({ name: "dashboard-home" });

      this.$nextTick(() => {
        this.$store.commit("app/setField", ["renderTableServices", true]);
      });
    },
  },
  computed: {
    ...mapState("app", [
      "user",
      "urlBucket",
      "currentMove",
      "lastMove",
      "logoPartner",
      "tableDataServices",
    ]),
  },
  watch: {
    currentMove: {
      handler(data) {
        console.log("currentMove - QuickActions", data);
        let date = new Date(data.submissionDate);

        // Get the current date
        let currentDateSubstracted = new Date();

        // Substract 180 days to the date
        currentDateSubstracted.setDate(currentDateSubstracted.getDate() - 180);

        // Compare the current date - 180 days > with the input date

        const toggleActions = (className, rule) => {
          Array.from(document.querySelectorAll(`.${className}`)).forEach((element) => {
            element.style.display = rule;
          });
        };

        setTimeout(() => {
          if (currentDateSubstracted < date) {
            toggleActions("action-displayed", "flex");
          } else {
            toggleActions("action-displayed", "none");
          }

        }, 100);
      },
      deep: true,
      immediate: true,
    },
    tableDataServices: {
      handler(data) {
        // console.log("tableDataServices - QuickActions", data);
        if (data.find((s) => s.status != "Update Sent")) {
          document.querySelector(".pause-my-move").style.display = "flex";
        } else {
          document.querySelector(".pause-my-move").style.display = "none";
        }
      },
      // immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.quick-actions-menu {
  /*display: none;*/
  margin-top: 230px;
  .action {
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    padding: 0.6em;
    padding-left: 0.7em;
  }
}
</style>
