<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!--:class="[
      { expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"  -->
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <div class="main-icon w-100 text-center mt-2" @click="navigateTo('/dashboard/home')">
          <img
            :src="`${urlBucket}/form/icons/slothmove-title.png`"
            width="170"
            class="img-fluid"
            alt=""
          />
        </div>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Nav Items -->
    <div class="nav-items px-2 mt-4 d-flex flex-column">
      <div>
        <div class="home d-flex align-items-center mb-2" @click="navigateTo('/dashboard/home')">
          <img :src="`${urlBucket}/dashboard/home-navbar-icon.png`" class="mr-1" width="45px" />
          <h5 style="color: #9b9b9b; font-size: 1.2rem" class="font-weight-bolder mb-0">Home</h5>
        </div>

        <div
          class="pending-actions d-flex align-items-center mb-2"
          @click="navigateTo('/dashboard/pending-actions')"
          v-if="displayPendingActionsWhen()"
        >
          <img
            :src="`${urlBucket}/dashboard/fire-icon-navbar.png`"
            class="mr-1"
            width="38px"
            style="margin-left: 5px"
          />
          <h5 style="color: #d9d9d9; font-size: 1.2rem" class="font-weight-bolder mb-0">
            Pending Actions
          </h5>
        </div>
        <!-- v-if="lastMove.plus" -->
        <div class="admin d-flex align-items-center mb-2" v-if="lastMove.plus">
          <img :src="`${urlBucket}/dashboard/fire-icon.png`" class="mr-1" width="45px" alt="" />
          <h5 style="color: #ff7f11; font-size: 1.2rem" class="font-weight-bolder mb-0 mr-2">
            Admin
          </h5>

          <img :src="`${urlBucket}/dashboard/new.png`" class="mr-2" width="54px" alt="" />
        </div>
        <!-- v-if="lastMove.plus" -->
        <div class="admin-subitems" v-if="lastMove.plus">
          <div
            v-for="(item, index) in navbarAdminItems"
            :key="index"
            class="item d-flex align-items-center pl-1 hvr-forward"
            :style="{
              'border-left': activeRoute(item['page'].replaceAll(' ', ''), item.color),
            }"
            @click="navigateTo({ name: item['page'].replaceAll(' ', '') })"
          >
            <img
              :src="`${urlBucket}/form/icons/${item['icon']}.png`"
              class=""
              width="36px"
              alt=""
            />

            <div style="margin-top: 5px">
              <p
                class="mb-0"
                :style="`color:${item['color']};font-weight: 700 !important; line-height: 0.8`"
              >
                {{ item["page"] }}
              </p>
              <p class="mb-0" style="">{{ item.subdescription }}</p>
            </div>
          </div>
        </div>
      </div>

      <QuickActionsMenu />
    </div>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref, watch, onMounted } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import store from "@/store/index.js";
import { mapGetters, mapState } from "vuex";
import { useRouter } from "@/@core/utils/utils.js";
import axiosCustom from "@axios";
import QuickActionsMenu from "./QuickActionsMenu.vue";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    QuickActionsMenu,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const router = useRouter();

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );
    const menuItems = ref(navMenuItems);
    const userPlan = computed(() => store.state.app.userPlan);
    const lastMove = computed(() => store.state.app.lastMove);

    watch(lastMove, (value) => {
      //
    });

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    const navigateTo = (path) => {
      router.router.push(path).catch((e) => {});
    };

    const activeRoute = (page, color) => {
      return router.route.value["name"] == page ? `7px solid ${color}` : null;
    };

    return {
      menuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      lastMove,
      navigateTo,
      activeRoute,
      navbarAdminItems: [
        {
          page: "SlothMove",
          subdescription: "Address changer",
          icon: "1", ///form/icons/
          color: "#20a7d7",
        },
        {
          page: "SlothGuard",
          subdescription: "Property protection",
          icon: "2",
          color: "#d0056b",
        },
        {
          page: "SlothUtility",
          subdescription: "Meter readings",
          icon: "3",
          color: "#ffe859",
        },
        {
          page: "SlothProtect",
          subdescription: "Data protection",
          icon: "4",
          color: "#59ff83",
        },
        {
          page: "Parking Ticket Fighter",
          subdescription: "Appeal ticket",
          icon: "5",
          color: "#59fff5",
        },
        // {
        //   page: "SlothQueue",
        //   subdescription: "Skip phone queue",
        //   icon: "6",
        //   color: "#ffa459",
        // },
        // {
        //   page: "Life Admin",
        //   subdescription: "Save time",
        //   icon: "7",
        //   color: "#fb58ea",
        // },
      ],
    };
  },
  mounted() {
    if (this.displayPendingActionsWhen()) {
      this.$router.push({ name: "PendingActions" });  
    }
  },
  methods: {
    displayPendingActionsWhen() {
      if (
        this.currentMove.pendingActions?.dvlaLicence["pending"] ||
        this.currentMove.pendingActions?.dvlaVehicle["pending"]      
      ) {
        return true;
      }
    }
  },
  computed: {
    ...mapState("app", ["user", "urlBucket", "logoPartner", "currentMove"]),
    ...mapGetters("app", ["isAPartner", "colorPartner"]),
  },
  watch: {
    currentMove(newVal) {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.main-menu {
  background-color: #1a1a1a !important;

  /*
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  */

  .main-icon {
    cursor: pointer;
  }

  .nav-items {
    height: 83%;
    .home,
    .pending-actions,
    .admin {
      cursor: pointer;
    }
  }

  .admin-subitems {
    .item {
      cursor: pointer;
      padding-left: 0.5rem;
      margin-bottom: 1.1rem;

      img {
        margin-right: 0.6rem;
      }

      p {
        font-size: 0.9rem;
        color: white;
      }
    }
  }
}
</style>
