<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notifications.length}}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-once
      class="scrollable-container media-list scroll-area"
      tagname="li"
      v-if="notifications.length"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification"
      >
        <p>{{ notifications }}</p>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li 
      class="dropdown-menu-footer"
      v-if="notifications.length"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >
        Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

import axiosCustom from '@axios';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      notifications: [],
    }
  },
  created(){
    axiosCustom.get(`api/user/getNotifications?email=${this.userData.email}`)
    .then( ({data}) => {
      this.notifications = data
    })
    .catch((error) => {
      this.$toast.warning("No available date", {
        style: {
          backgroundColor: '#ecb90d',
          color: '#fff'
        }
      })
    })
    .finally(() => {
      this.loading = false
    })
  },
}
</script>

<style>
  .btn-primary {
    border-color: #18a8d6 !important;
    background-color: #18a8d6 !important;
  }
</style>
