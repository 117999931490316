<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    <!-- </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <button
        v-if="userPlan.plan !== 2"
        class="btn badge badge-glow badge-success mr-1 font-weight-bolder upgrade"
        @click="toPricing()"
      >
        UPGRADE
      </button> -->
  
      <!-- <search-bar /> -->

      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import SearchBar from "./components/SearchBar.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import axiosCustom from '@axios';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    SearchBar,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods:{
    toPricing(){
      this.$router.push('/pages/pricing');
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user) {
      axiosCustom
        .get(`api/user/getDetails?email=${user.email}`)
        .then(({ data }) => {
          let startDate = new Date(
            data.moves[data.moves.length - 1].submissionDateFormatted
          );
          let endDate = new Date(startDate);
          let month = endDate.getMonth() + 4;
          endDate.setMonth(month);
          
          let endDateFormatted = moment(endDate).format('DD-MM-YYYY');
          this.$store.commit("app/setField", [
            "userPlan",
            { 
              plan: data.plan ?? undefined, 
              endDate, 
              endDateFormatted 
            }
          ]);
        });
    }
  },
  computed:{
    ...mapState("app", ["userPlan","lastMove"]),
  }
};
</script>

<style lang="scss" scope>
.upgrade {
  color: #f8edef !important;
  padding: 0.7rem 1rem !important;
  background-color: #20476f;
  box-shadow: 0px 0px 10px #20476f!important;
}

a.badge-success:focus {
  background-color: #20476f;
  box-shadow: 0px 0px 10px #20476f;
}
</style>
